import React, { Component } from 'react';
import { MealOption, Dietary } from "./RsvpLookup";
import './RsvpPersonEntry.scss';

export interface RsvpPersonEntryProps {
    mealOptions: MealOption[];
    personNumber: number;
    updateRsvpPersonBody: (personNumber: number, rsvp: RsvpPersonEntryState) => void;
}

export interface RsvpPersonBody {
    first_name: string;
    last_name: string;
    meal: string;
}

export interface RsvpPersonEntryState {
    valid: boolean;
    person: RsvpPersonBody;
}

export interface MealOptionSelectorProps {
    onSelected: (mealId: string) => void;
    mealOption: MealOption;
    personNumber: number;
}

export class DietaryTags extends Component<{ dietary: Dietary }> {
    render() {
        return <>
            {this.props.dietary.diary_free ? <span className="tag">Dairy Free</span> : null}
            {this.props.dietary.gluten_free ? <span className="tag">Gluten Free</span> : null}
            {this.props.dietary.vegetarian ? <span className="tag">Vegetarian</span> : null}
        </>
    }
}

export class MealOptionSelector extends Component<MealOptionSelectorProps> {
    inputSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSelected(this.props.mealOption.id);
    }

    render() {
        let id = "meal_" + this.props.personNumber + "_" + this.props.mealOption.id.replace(/[^a-zA-Z0-9_]+/g, '_');

        return <div className="meal-option">
            <div className="checkbox">
                <input type="radio" name={"meal_" + this.props.personNumber} onChange={this.inputSelected} id={id} />
            </div>
            <label htmlFor={id}>
                <strong>{this.props.mealOption.title}</strong>
                <br /><em>{this.props.mealOption.subtitle}</em>
                <small>
                    <br />
                    <DietaryTags dietary={this.props.mealOption.dietary} />
                </small>
            </label>
        </div>
    }
}

export class RsvpPersonEntry extends Component<RsvpPersonEntryProps, RsvpPersonEntryState> {
    constructor(props: Readonly<RsvpPersonEntryProps>) {
        super(props);

        this.state = {
            valid: false,
            person: {
                first_name: "",
                last_name: "",
                meal: ""
            },
        };
    }

    updatePerson(person: RsvpPersonBody) {
        let valid = person.last_name.length > 0
            && person.first_name.length > 0
            && person.meal.length > 0;

        this.setState({ person, valid });
        this.props.updateRsvpPersonBody(this.props.personNumber, { valid, person });
    }

    select = (mealId: string) => {
        let person = this.state.person;
        person.meal = mealId;

        this.updatePerson(person);
    };

    setFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = (e.target as HTMLInputElement).value;
        let person = this.state.person;
        person.first_name = val;

        this.updatePerson(person);
    }

    setLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = (e.target as HTMLInputElement).value;
        let person = this.state.person;
        person.last_name = val;

        this.updatePerson(person);
    }

    render() {
        return <div className="person-entry">
            <h2>Person #{this.props.personNumber}</h2>
            <h3>Name</h3>
            <p className="input-container">
                <input type="text" placeholder="First Name" value={this.state.person.first_name} onChange={this.setFirstName} autoCapitalize="words" />
            </p>
            <p className="input-container">
                <input type="text" placeholder="Last Name" value={this.state.person.last_name} onChange={this.setLastName} autoCapitalize="words" />
            </p>
            <h3>Dinner choice{this.state.person.first_name.length > 0 ? " for " + this.state.person.first_name : null}</h3>
            <ul>
                {this.props.mealOptions.map(m =>
                    <li key={m.id}>
                        <MealOptionSelector
                            personNumber={this.props.personNumber}
                            mealOption={m}
                            onSelected={this.select} />
                    </li>
                )}
            </ul>
        </div>
    }
}
