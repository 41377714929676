import React, { Component } from 'react';
import './Envelope.scss';
import specialDelivery from './special-delivery@0,5x.png';
import specialDeliveryRetina from './special-delivery@2x.png';
import debounce from 'lodash/debounce';
import { RsvpLookup, lookupRsvp } from './RsvpLookup';
import { Rsvp } from './Rsvp';
import { HeartSpinner } from '../heart-spinner';

interface RsvpState {
    search: string;
    rsvpLookup: RsvpLookup | null;
    loading: boolean;
}

function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}

class Envelope extends Component<{}, RsvpState> {
    private nameInput: HTMLInputElement | null = null;

    constructor(props: {}) {
        super(props);

        this.state = {
            search: getQueryVariable('name') || '',
            rsvpLookup: null,
            loading: false
        };
    }

    componentDidMount() {
        const name = this.state.search;

        if (name && name.length > 0) {
            this.setState({ search: name });
            this.lookup(null);
        }
    }


    updateRsvp = (rsvp: RsvpLookup) => {
        this.setState({ rsvpLookup: rsvp });
    }

    reset = () => {
        this.setState({ rsvpLookup: null });

        setTimeout(() => this.nameInput && this.nameInput.focus(), 0);
    }

    updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            search: (event.target as HTMLInputElement).value
        })
    }

    lookup = async (event: React.FormEvent<HTMLFormElement> | null) => {
        event && event.preventDefault();

        this.setState({ loading: true });

        let r = await lookupRsvp(this.state.search);

        this.setState({
            rsvpLookup: r,
            loading: false
        })
    }

    render() {
        let form = null;
        let help = null;

        if (!this.state.rsvpLookup || !this.state.rsvpLookup.matches) {
            let incorrectName = this.state.rsvpLookup && !this.state.rsvpLookup.matches;

            form = <form name="rsvp" id="rsvp" onSubmit={this.lookup}>
                <p>
                    <label htmlFor="name">
                        What is the name on your invitation?
                    </label>
                    <input name="name" type="text"
                        placeholder="John Doe and Guest"
                        value={this.state.search} onChange={this.updateSearch}
                        ref={input => this.nameInput = input} />
                </p>
                {incorrectName ?
                    <p><strong>We can't find that name on our guestlist.</strong></p>
                    : null}
                <p>Make sure you enter your name exactly as it was addressed on your envelope. Examples include: “The Doe Family”, “Jane Doe and Guest”, “Mr. John Smith”, and “Jack, Jill, and Jane Johnson”.</p>
                <p>If that still isn’t working or if you have any questions or concerns, please email us at <a href="mailto:thecouple@goingorge.com">thecouple@goingorge.com</a>!</p>

                <p className="submit-row">
                    {this.state.loading ? null : <input className="btn" type="submit" value="RSVP" />}
                </p>
            </form>
        }

        return (
            <div className="picture">
                {/* <p> */}
                    {/* <img src={specialDelivery} srcSet={`${specialDelivery} 1x, ${specialDeliveryRetina} 2x`} alt="Special delivery for:" /> */}
                {/* </p> */}
                {form}
                <HeartSpinner loading={this.state.loading} />
                <Rsvp rsvp={this.state.rsvpLookup} reset={this.reset} updateRsvp={this.updateRsvp} />
                {help}
            </div>
        );
    }
}

export default Envelope;
