import React, { Component } from 'react';
import { RsvpLookup, declineRsvp, acceptRsvp } from "./RsvpLookup";
import { RsvpPerson } from "./RsvpPerson";
import './Rsvp.scss';
import { RsvpPersonEntry, RsvpPersonBody, RsvpPersonEntryState } from './RsvpPersonEntry';
import { HeartSpinner } from '../heart-spinner';
import ReactDOM from 'react-dom';

export interface RsvpProps {
    rsvp: RsvpLookup | null;
    reset: () => void;
    updateRsvp: (rsvp: RsvpLookup) => void;
}

interface RsvpState {
    loading: boolean;
    rsvp: RsvpLookup | null;
    selectedSeats: number;
    rsvpPersons: RsvpPersonEntryState[];
    brunch: string | null;
    justSubmitted: boolean;
    changingRsvp: boolean;
}

interface RsvpButtonProps {
    seatDescription: string;
    onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class RsvpButton extends Component<RsvpButtonProps> {
    render() {
        let id = "rsvp_count_" + this.props.seatDescription;
        return <div className="meal-option">
            <div className="checkbox">
                <input type="radio" name="rsvp_count" onChange={this.props.onClick} id={id} />
            </div>
            <label htmlFor={id}>
                <strong>{this.props.seatDescription}</strong>
            </label>
        </div>
    }
}

export class Rsvp extends Component<RsvpProps, RsvpState> {
    constructor(props: React.PropsWithoutRef<RsvpProps>) {
        super(props);

        this.state = {
            loading: false,
            rsvp: props.rsvp,
            selectedSeats: -1,
            rsvpPersons: [],
            brunch: null,
            justSubmitted: false,
            changingRsvp: false
        };
    }

    componentWillReceiveProps(nextProps: RsvpProps) {
        this.setState({ rsvp: nextProps.rsvp });
    }

    reset = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        this.props.reset();
        this.setState({ selectedSeats: -1, rsvpPersons: [], brunch: null, justSubmitted: false });
    };

    triggerChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({ changingRsvp: true });
    }

    decline = async (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ loading: true, selectedSeats: -1 });

        let rs = await declineRsvp(this.state.rsvp as RsvpLookup);

        this.setState({ rsvp: rs, loading: false });
        this.props.updateRsvp(rs);
    };

    attending = (seats: number) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ selectedSeats: seats, rsvpPersons: [] });
        };
    }

    rsvpUpdated = (personNumber: number, rsvp: RsvpPersonEntryState) => {
        let rsvpPersons = this.state.rsvpPersons;
        rsvpPersons[personNumber - 1] = rsvp;

        this.setState({ rsvpPersons });
    }

    submitRsvp = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let rsvp = this.props.rsvp;
        var newRsvp;

        this.setState({ loading: true });

        if (rsvp && this.state.selectedSeats == 0) {
            newRsvp = await declineRsvp(rsvp);

            this.props.updateRsvp(newRsvp);
        }

        let brunch = this.state.brunch;

        if (rsvp && brunch) {
            newRsvp = await acceptRsvp(rsvp, brunch as any, this.state.rsvpPersons.map(r => r.person));

            this.props.updateRsvp(newRsvp);
        }

        if (newRsvp) {
            this.setState({ loading: false, rsvp: newRsvp, brunch: null, selectedSeats: 0, justSubmitted: true });

            let el = ReactDOM.findDOMNode(this) as Element;
            el.scrollIntoView(true);
        }
    };

    changeBrunch = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ brunch: (e.target as HTMLInputElement).value });
    };

    render() {
        if (!this.state || !this.state.rsvp || !this.state.rsvp.matches) {
            return null;
        }

        let rsvp = this.state.rsvp;
        let match = rsvp.match;
        let prev = null;

        let isSingle = match.seats == 1;

        if (match.rsvp_date && match.rsvp_date.length > 0) {
            let d = new Date(match.rsvp_date);
            prev = <>
                <p><strong>We recorded your RSVP on {d.toLocaleDateString()} as:</strong></p>
                {rsvp.rsvps.map((r, idx) =>
                    <RsvpPerson key={idx} rsvp={r} mealOptions={rsvp.meal_options} />
                )}
                {rsvp.rsvps.length == 0 ? <p><em>{(isSingle ? 'I' : 'We')} regretfully decline</em></p> : null}
                {rsvp.rsvps.length > 0 ? <p>Attending brunch? <strong>{match.brunch}</strong></p> : null}
                {this.state.changingRsvp || this.state.justSubmitted ? null : <p>If you wish, you may update your RSVP below.</p>}
                <hr />
                {this.state.changingRsvp || this.state.justSubmitted ? null : <button className="btn" onClick={this.triggerChange}>Change RSVP</button>}
            </>
        }

        let attendanceButtons = [<RsvpButton onClick={this.attending(1)} seatDescription={`Yes, I will be attending`} />];

        if (!isSingle) {
            attendanceButtons = Array.from(Array(match.seats).keys()).map(idx =>
                <RsvpButton onClick={this.attending(idx + 1)} seatDescription={`Yes, ${idx + 1} will be attending`} />
            );
        }

        let btns: JSX.Element | null = <div className="person-entry rsvp-option-buttons">
            {attendanceButtons.map((b, idx) => <div className="p" key={"seat_" + idx}>{b}</div>)}
            <div className="p">
                <RsvpButton onClick={this.attending(0)} seatDescription={`No, ${isSingle ? 'I' : 'we'} regretfully decline`} />
            </div>
        </div>;
        let personEntries = null;

        let submittable = this.state.selectedSeats == 0;
        
        if (!submittable) {
            let validSeats = this.state.rsvpPersons.length == this.state.selectedSeats;
            let entriesValid = this.state.rsvpPersons.filter(f => !f.valid).length === 0;
            let brunchValid = this.state.brunch !== null && ["Yes", "No", "Maybe"].indexOf(this.state.brunch) !== -1;
            submittable = validSeats && entriesValid && brunchValid;    
        }

        let submitRsvp = <>
            {this.state.justSubmitted || (!this.state.changingRsvp && prev != null) ? null : <hr />}
            <HeartSpinner loading={this.state && this.state.loading} />
            <br />
            {!this.state.justSubmitted && submittable && !this.state.loading ?
                <input type="submit" value="Send RSVP" className="btn" />
            : null}
        </>;
        
        let brunch = null;

        if (this.state.justSubmitted || (!this.state.changingRsvp && prev != null)) {
            btns = null;
        }

        if (this.state.selectedSeats > 0) {
            personEntries = <><hr /> {Array.from(Array(this.state.selectedSeats).keys()).map(idx =>
                <RsvpPersonEntry
                    key={"rsvp_person_" + this.state.selectedSeats + "_" + idx}
                    personNumber={idx + 1}
                    mealOptions={rsvp.meal_options}
                    updateRsvpPersonBody={this.rsvpUpdated}
                />
            )}</>;

            brunch = <>
                <hr />
                <h3>Will you be attending brunch?</h3>
                <p>
                    <em>Sunday, June 9, 10:30 AM&ndash;1 PM</em>
                </p>
                <p>Before we leave on our honeymoon, join us for brunch! Come on by for as little or as long as you like to enjoy some food.</p>
                <p className="brunch">
                    <label>
                        <input type="radio" name="brunch" value="Yes" onChange={this.changeBrunch} /> Yes
                    </label>
                    <label>
                        <input type="radio" name="brunch" value="Maybe" onChange={this.changeBrunch} /> Maybe
                    </label>
                    <label>
                        <input type="radio" name="brunch" value="No" onChange={this.changeBrunch} /> No
                    </label>
                </p>
            </>;
        }

        return <div className="rsvp-options">
            <h2>
                Hello, {match.name_on_envelope}!
                <br />
                <small>We mailed your invitation to {match.location}. (<a href="/" onClick={this.reset}>this isn't me</a>)</small>
            </h2>
            {this.state.justSubmitted
                ? null
                : <>
                <h3>Will you be attending our wedding?</h3>
                <p>
                    <em>Saturday, June 8, 4 PM in Minneapolis</em>
                </p>
            </>}
            <hr />
            {prev}
            {btns}
            <form onSubmit={this.submitRsvp}>
                {personEntries}
                {brunch}
                {submitRsvp}
            </form>
        </div>;
    }
}
