import React, { Component } from 'react';
import './heart-spinner.scss';

export interface HeartSpinnerProps {
    loading: boolean;
}

export class HeartSpinner extends React.Component<HeartSpinnerProps, HeartSpinnerProps> {
    constructor(props: Readonly<HeartSpinnerProps>) {
        super(props);

        this.state = { loading: props.loading };
    }

    componentWillReceiveProps(props: Readonly<HeartSpinnerProps>) {
        this.setState({ loading: props.loading });
    }
    
    render() {
        if (!this.state || !this.state.loading) {
            return null;
        }

        return <div className="lds-heart"><div></div></div>
    }
}
