import { RsvpPersonBody } from "./RsvpPersonEntry";

export interface Match {
    name_on_envelope: string;
    location: string;
    seats: number;
    received_rsvp: string;
    rsvp_date: string;
    rsvp_seats: number;
    herb_chicken: number;
    beef_short_ribs: number;
    bbq_salmon: number;
    ravioli: number;
    brunch: string;
    search_name: string;
}

export interface Dietary {
    gluten_free: boolean;
    diary_free: boolean;
    vegetarian: boolean;
}

export interface MealOption {
    id: string;
    title: string;
    subtitle: string;
    dietary: Dietary;
}

export interface Rsvp {
    envelope: string;
    first_name: string;
    last_name: string;
    meal: string;
}

export interface RsvpLookup {
    matches: boolean;
    match: Match;
    meal_options: MealOption[];
    rsvps: Rsvp[];
}

export function mealForMealId(id: string, mealOptions: MealOption[]) : MealOption {
    return mealOptions.filter(m => m.id == id)[0];
}

export const api_url = "/.netlify/functions";
// export const api_url = "https://script.google.com/macros/s/AKfycbzeq06EflOfnRqUT0zrakBR7iLeCTbysk_7rDymCv13TW_gNhc/exec";
const urls = {
    lookup: (query: string) => (api_url[0] == '/' ? api_url + '/lookup' : api_url) + '?name=' + encodeURIComponent(query),
    rsvp: () => (api_url[0] == '/' ? api_url + '/rsvp' : api_url)
}

export async function lookupRsvp(query: string) {
    const resp = await fetch(urls.lookup(query));
    return (await resp.json()) as RsvpLookup;
}

export async function declineRsvp(lookup: RsvpLookup) {
    const resp = await fetch(urls.rsvp(), {
        method: "POST",
        body: JSON.stringify({
            name_on_envelope: lookup.match.name_on_envelope,
            brunch: "No",
            rsvps: []
        })
    });

    return await lookupRsvp(lookup.match.name_on_envelope);
}

export async function acceptRsvp(lookup: RsvpLookup, brunch: "Yes" | "No" | "Maybe", rsvps: RsvpPersonBody[]) {
    const resp = await fetch(urls.rsvp(), {
        method: "POST",
        body: JSON.stringify({
            name_on_envelope: lookup.match.name_on_envelope,
            brunch: brunch,
            rsvps: rsvps
        })
    });

    return await lookupRsvp(lookup.match.name_on_envelope);
}
