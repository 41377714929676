import React, { Component } from 'react';
import { Rsvp, MealOption, mealForMealId } from "./RsvpLookup";
import './Rsvp.scss';

export interface RsvpPersonProps {
    rsvp: Rsvp;
    mealOptions: MealOption[];
}

export class RsvpPerson extends Component<RsvpPersonProps> {
    render() {
        let r = this.props.rsvp;
        return <div>
            {r.first_name} {r.last_name}: {mealForMealId(r.meal, this.props.mealOptions).title}
        </div>
    }
}
